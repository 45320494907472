/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, ListSubheader, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Clipboard as ClipboardIcon,
    Users as UsersIcon,
    List as ListIcon,
    Video as VideoIcon,
    Box as BoxIcon,
    ChevronDown as ChevronDownIcon,
    ChevronUp as ChevronUpIcon,
    CheckCircle as CheckCircleIcon,
    Home as HomeIcon,
    FileText as FileTextIcon,
    CheckSquare as CheckSquareIcon
} from 'react-feather';
import NavItem from './NavItem';
import axios from 'axios';
import { getWSConfig } from 'src/views/utils/Utils';
import { numberOfSubcriptionsInLeftMenu } from 'src/views/utils/UtilsConstants';
import { log, logError } from 'src/views/utils/logger';
import { useIntl } from 'react-intl';

interface NavBarProps {
    onMobileClose: () => void;
    openMobile: boolean;
}

interface Item {
    href?: string;
    icon?: ReactNode;
    info?: ReactNode;
    items?: Item[];
    title: string;
    image?: string;
}

interface Section {
    items: Item[];
    subheader: string;
}

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
        zIndex: theme.zIndex.appBar - 1
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    menuItem: {
        marginBottom: '0.5rem',
        '& a': {
            paddingLeft: '0rem',
            '& span': {
                '& span': {
                    marginLeft: '1.5rem',
                }
            }
        }
    },
    menuItemSeeMoreSeeLess: {
        marginBottom: '0.5rem',
        '& a': {
            paddingLeft: '0rem',
            '& span': {
                '& span': {
                    marginLeft: '1.5rem',
                    color: theme.palette.text.secondary,
                },
                '& svg': {
                    color: theme.palette.text.secondary + "!important",
                },
            }
        }
    },
    invisible: {
        display: 'none'
    },
    leftPadding: {
        paddingLeft: '0.5rem'
    }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const [subscriptionsInLeftMenu, setSubscriptionsInLeftMenu] = useState<Item[]>([]);
    const [seeAllMySubscriptions, setSeeAllMySubscriptions] = useState<Boolean>(false);
    const config = getWSConfig();
    const intl = useIntl();

    const sections: Section[] = [
        {
            subheader: '',
            items: [
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.content_management' }),
                    icon: VideoIcon,
                    href: '/app/upload/video',
                },
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.my_content' }),
                    icon: VideoIcon,
                    href: '/app/myContent',
                },
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.channel_view' }),
                    icon: ListIcon,
                    href: '/app/channelview',
                },
                {
                    title: intl.formatMessage({ id: 'layouts.DashboardLayout.NavBar.index.questionnaires' }),
                    icon: CheckSquareIcon,
                    href: '/app/questionnaires/',
                },
            ]
        }
    ];

    const seeLess = (): void => {
        setSeeAllMySubscriptions(false);
    };

    const seeMore = (): void => {
        setSeeAllMySubscriptions(true);
    };

    function renderNavItems(
        items: Item[],
        pathname: string,
        maximumElementsToShow: number,
        depth: number = 0,
        classes) {
        return (
            <List disablePadding>
                {items.reduce(
                    (acc, item) => reduceChildRoutes(acc, pathname, item, depth, maximumElementsToShow, classes),
                    []
                )}
            </List>
        );
    }

    function reduceChildRoutes(
        acc: any[],
        pathname: string,
        item: Item,
        depth: number,
        maximumElementsToShow: number,
        classes) {
        const key = item.title + depth;

        if (item.items) {
            const open = matchPath(pathname, {
                path: item.href,
                exact: false
            });

            acc.push(
                <NavItem
                    depth={depth}
                    icon={item.icon}
                    info={item.info}
                    key={key}
                    open={Boolean(open)}
                    title={item.title}
                    className={classes.menuItem}
                    image={item.image}
                >
                    {renderNavItems(
                        item.items,
                        pathname,
                        maximumElementsToShow,
                        depth + 1,
                        classes)}
                </NavItem>
            );
        } else {
            const lengthArrayAcc = acc.length;
            if (lengthArrayAcc < maximumElementsToShow) {
                acc.push(
                    <NavItem
                        depth={depth}
                        href={item.href}
                        icon={item.icon}
                        info={item.info}
                        key={key}
                        title={item.title}
                        className={classes.menuItem}
                        image={item.image}
                    />
                );
            }
            if (lengthArrayAcc === maximumElementsToShow) {
                acc.push(
                    <NavItem
                        depth={depth}
                        href="#"
                        onClick={seeMore}
                        icon={ChevronDownIcon}
                        key="seeMore"
                        title="Ver más"
                        className={seeAllMySubscriptions ? classes.invisible : classes.menuItemSeeMoreSeeLess}
                    />
                );

                acc.push(
                    <NavItem
                        depth={depth}
                        href={item.href}
                        icon={item.icon}
                        info={item.info}
                        key={key}
                        title={item.title}
                        className={seeAllMySubscriptions ? classes.menuItem : classes.invisible}
                        image={item.image}
                    />
                );

                acc.push(
                    <NavItem
                        depth={depth}
                        href="#"
                        onClick={seeLess}
                        icon={ChevronUpIcon}
                        key="seeLess"
                        title="Ver menos"
                        className={seeAllMySubscriptions ? classes.menuItemSeeMoreSeeLess : classes.invisible}
                    />
                );
            }
            if (lengthArrayAcc > maximumElementsToShow) {
                acc.pop();
                acc.push(
                    <NavItem
                        depth={depth}
                        href={item.href}
                        icon={item.icon}
                        info={item.info}
                        key={key}
                        title={item.title}
                        className={seeAllMySubscriptions ? classes.menuItem : classes.invisible}
                        image={item.image}
                    />
                );
                acc.push(
                    <NavItem
                        depth={depth}
                        href="#"
                        onClick={seeLess}
                        icon={ChevronUpIcon}
                        key="seeLess"
                        title="Ver menos"
                        className={seeAllMySubscriptions ? classes.menuItemSeeMoreSeeLess : classes.invisible}
                    />
                );
            }
        }

        return acc;
    }

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            mt={4}
        >
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Box p={2} className={classes.leftPadding}>
                    {sections.map((section) => (
                        <List
                            key={section.subheader}
                            subheader={(
                                <ListSubheader
                                    disableGutters
                                    disableSticky
                                >
                                    {section.subheader}
                                </ListSubheader>
                            )}
                        >
                            {renderNavItems(
                                section.items,
                                location.pathname,
                                100,
                                0,
                                classes)
                            }
                        </List>
                    ))}
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return <>
        <Hidden lgUp>
            <Drawer
                anchor="left"
                classes={{ paper: classes.mobileDrawer }}
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
            >
                {content}
            </Drawer>
        </Hidden>
        <Hidden lgDown>
            <Drawer
                anchor="left"
                classes={{ paper: classes.desktopDrawer }}
                open
                variant="persistent"
            >
                {content}
            </Drawer>
        </Hidden>
    </>;
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
