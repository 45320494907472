import React, {
    useRef,
    useState
} from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Avatar from 'react-avatar';
import { Box, ButtonBase, Hidden, Menu, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ProfessionalMedic } from 'src/types/professionalMedic';
import useAuth from 'src/hooks/useAuth';
import { OrganizationAdmin } from 'src/types/organizationAdmin';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1)
    },
    popover: {
        width: 200
    }
}));

const Account: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const ref = useRef<any>(null);
    const { logout } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setOpen] = useState<boolean>(false);
    const organizationAdmin: OrganizationAdmin = JSON.parse(localStorage.getItem('user'));

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = async (): Promise<void> => {
        try {
            handleClose();
            logout();
            history.push('/');
        } catch (err) {
            console.error(err);
            enqueueSnackbar('Unable to logout', {
                variant: 'error'
            });
        }
    };

    return <>
        <Box
            display="flex"
            alignItems="center"
            component={ButtonBase}
            onClick={handleOpen}
            // @ts-ignore
            ref={ref}
        >
            <Avatar
                name={organizationAdmin.organization_name}
                maxInitials={2}
                size="30"
                round={true}
                color="#E0E0E0"
                className={classes.avatar}
            />
            <Hidden mdDown>
                <Typography
                    variant="h6"
                    color="textPrimary"
                >
                    {organizationAdmin.organization_name}
                </Typography>
            </Hidden>
        </Box>
        <Menu
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            anchorEl={ref.current}
            open={isOpen}
        >
            <MenuItem onClick={handleLogout}>
                <FormattedMessage id="layouts.DashboardLayout.TopBar.Account.exit" />
            </MenuItem>
        </Menu>
    </>;
}

export default Account;
