import React, {
    Suspense,
    Fragment,
    lazy
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';

type Routes = {
    exact?: boolean;
    path?: string | string[];
    guard?: any;
    layout?: any;
    component?: any;
    routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes: Routes = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView'))
    },
    {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        path: '/passwordRecovery',
        component: lazy(() => import('src/views/auth/PasswordRecoveryView'))
    },
    {
        exact: true,
        path: '/reset/password/:token/:redirect_url?',
        component: lazy(() => import('src/views/auth/ResetPasswordView'))
    },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app/channelview',
                component: lazy(() => import('src/views/channelView'))
            },
            {
                exact: true,
                path: '/app/questionnaires',
                component: lazy(() => import('src/views/questionnaires/gallery'))
            },
            {
                exact: true,
                path: '/app/questionnaires/answers/:questionnaire_answers_id',
                component: lazy(() => import('src/views/questionnaires/results'))
            },
            {
                exact: true,
                path: '/app/account',
                component: lazy(() => import('src/views/account/AccountView'))
            },
            {
                exact: true,
                path: '/app/upload/video',
                component: lazy(() => import('src/views/uploadVideo/index'))
            },
            {
                exact: true,
                path: '/app/myContent',
                component: lazy(() => import('src/views/myContent/index'))
            },
        ]
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/'
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    }
];

export default routes;
