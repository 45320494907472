import type { FC } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            height: 'auto',
            width: '100%',
            backgroundColor: "#f4f6f8",
        },
        '#root': {
            height: '100%',
            width: '100%'
        }
    }
}));

const GlobalStyles: FC = () => {
    useStyles();

    return null;
};

export default GlobalStyles;
