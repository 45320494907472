import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import JwtDecode from 'jwt-decode';

interface AuthGuardProps {
    children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {

    const { logout } = useAuth();

    let accessToken: string = localStorage.getItem('accessToken');

    if (accessToken != null) {
        const decoded: any = JwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        if (decoded.exp <= currentTime) {
            logout();
            return <Redirect to="/?expired=true" />;
        }
    }
    else {
        logout();
        return <Redirect to="/" />;
    }

    return (
        <>
            {children}
        </>
    );
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
