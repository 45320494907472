import React, {
    useState,
    useEffect
} from 'react';
import type { FC } from 'react';
import Cookies from 'js-cookie';
import { Box, Button, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { theme } from 'src/theme';
import Portal from '@mui/material/Portal';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        maxWidth: 600,
        position: 'fixed',
        bottom: 0,
        left: 0,
        margin: theme.spacing(3),
        padding: theme.spacing(3),
        outline: 'none',
        zIndex: 2000
    },
    action: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black
    }
}));

const CookiesNotification: FC = () => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);

    const handleClose = () => {
        Cookies.set('consent', 'true');
        setOpen(false);
    };

    useEffect(() => {
        const consent: string | undefined = Cookies.get('consent');

        if (!consent) {
            setOpen(true);
        }
    }, []);

    if (!open) {
        return null;
    }

    return (
        <Portal>
            <div className={classes.root}>
                <Typography
                    variant="body1"
                    color="inherit"
                >
                    <FormattedMessage id="components.CookiesNotification.cookies" />
                    {' '}
                    <Link
                        component="a"
                        color="inherit"
                        underline="always"
                        href="https://digimevo.net/privacypolicy"
                        target="_blank"
                    >
                        <FormattedMessage id="components.CookiesNotification.privacy_policy" />
                    </Link>
                    .
                </Typography>
                <Box
                    mt={2}
                    display="flex"
                    justifyContent="flex-end"
                >
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className={classes.action}
                    >
                        <FormattedMessage id="components.CookiesNotification.i_agree" />
                    </Button>
                </Box>
            </div>
        </Portal>
    );
};

export default CookiesNotification;
